import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthService } from '../../services';

@Component({
  selector: 'co2-login',
  styleUrls: ['./login.container.scss'],
  templateUrl: './login.container.html'
})
export class LoginContainer implements OnInit, OnDestroy {
  authenticationPending = true;
  private readonly _destroying$ = new Subject<void>();

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
    // Redirect authenticated user to home
    this.authService.redirectAuthenticatedUserToHome();

    // Update authentication process status
    this.authService.authenticationPending
      .pipe(
        filter((state: boolean) => !state),
        takeUntil(this._destroying$)
      )
      .subscribe({
        next: (state) => {
          this.authenticationPending = state;
        }
      });
  }

  login(): void {
    this.authService.login();
  }

  ngOnDestroy(): void {
    this._destroying$.complete();
  }
}
